export enum CreditCard {
    AmericanExpress = 'amex',
    Argencard = 'argencard',
    Cabal = 'cabal',
    Consosud = 'censosud',
    DinersClub = 'diners',
    Discover = 'discover',
    Elo = 'elo',
    Hipercard = 'hipercard',
    JCB = 'jcb',
    Mastercard = 'mastercard',
    Naranja = 'naranja',
    TarjetaShopping = 'targeta-shopping',
    UnionChinaPay = 'union-china-pay',
    Visa = 'visa',
    MIR = 'mir',
    Maestro = 'maestro',
}