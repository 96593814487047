export enum Browser {
    AvantBrowser = 'aa',
    AndroidWebViewBeta = 'an',
    GoogleChrome = 'ch',
    GoogleChromeIOS = 'ci',
    GoogleChromeMobile = 'cm',
    Chromium = 'cr',
    MozillaFirefox = 'ff',
    Safari = 'sf',
    MobileSafari = 'mf',
    MicrosoftEdge = 'ps',
    MicrosoftEdgeIOS = 'oi',
    OperaMini = 'om',
    Opera = 'op',
    OperaNext = 'on',
}